import React from "react";

import SocialNavBar from "../../Components/ui/SocialNavBar";

const footerData = {
  ContactUs: [
    { name: "505, Sukhsagar Complex," },
    { name: "Nr. Hotel Fortune Landmark," },
    { name: "Usmanpura Cross Road," },
    { name: "Ashram Road, Ahmedabad -" },
    { name: "380013." },
  ],
  Support: [
    {
      name: "Live Care",
      url: "https://www.easyofficesoftware.com/public/assets_front/software/LiveCare.exe",
    },
    { name: "Software Video", url: "https://www.easyofficesoftware.com/video" },
    { name: "Accounts Review", url: "#" },
    { name: "FAQ's", url: "#" },
    { name: "Demo", url: "https://www.easyofficesoftware.com/product_demo" },
  ],
  Usefull_link: [
    { name: "About", url: "https://www.easyofficesoftware.com/about" },
    { name: "Blog", url: "https://www.easyofficesoftware.com/blog" },
    {
      name: "Product Catalogue",
      url: "https://www.easyofficesoftware.com/product_catalogue",
    },
    {
      name: "Bank Details",
      url: "https://www.easyofficesoftware.com/bank_details",
    },
    { name: "Sitemap", url: "" },
  ],
  Our_Software: [
    {
      name: "EasyOFFICE",
      url: "https://www.easyofficesoftware.com/easyofficesoftware",
    },
    { name: "EasyGST", url: "https://www.easyofficesoftware.com/easygst" },
    { name: "EasyACC", url: "https://www.easyofficesoftware.com/easyacc" },
    {
      name: "EasyTAX",
      url: "https://www.easyofficesoftware.com/incometaxsoftware",
    },
    {
      name: "EasyAUDIT",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    { name: "EasyTDS", url: "https://www.easyofficesoftware.com/tdssoftware" },
    { name: "EasyCMA", url: "https://www.easyofficesoftware.com/cmasoftware" },
  ],
};

const Footer = React.memo(() => {
  return (
    <>
      <footer class="bg-gray-800" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">
          Footer
        </h2>
        <div class="mx-auto max-w-7xl px-6 pb-8 pt-10 sm:pt-24 lg:px-8 lg:pt-20">
          <div class="xl:grid xl:grid-cols-3 xl:gap-8">
            <div class="space-y-5">
              <h2 className="font-bold text-4xl text-gray-100">
                Electrocom Software PVT.LTD
              </h2>
              <p class="max-w-xs flex items-center mt-4 text-lg text-gray-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-telephone-fill mr-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                  />
                </svg>
                <p className="text-gray-100">
                  079-27562400 <br />
                  079-35014600
                </p>
              </p>
              <p>
                <a
                  href="https://www.easyofficesoftware.com/"
                  className="text-gray-100 hover:text-gray-300"
                >
                  easyofficesoftware.com
                </a>
              </p>
              <p class="max-w-xs flex items-center text-lg text-gray-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-envelope-fill mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                </svg>{" "}
                info@electrocom.in
              </p>
              <SocialNavBar Color={"bg-gray-100"} />
            </div>
            <div className="sm:flex sm:mt-0 mt-16 col-span-2 justify-evenly">
              {Object.entries(footerData).map(([category, items]) => (
                <div key={category} className="">
                  <p className="text-2xl mb-3 mt-4 sm:mb-0 sm:mt-0 font-bold text-gray-100">
                    {category}
                  </p>
                  <div className="">
                    {items.map((item) => (
                      <div key={item.id}>
                        {" "}
                        <ul role="list" className="mt-3">
                          <li>
                            <a
                              href={item.url}
                              className="text-base leading-6 text-gray-100 hover:text-gray-400"
                            >
                              {item.name}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div class="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
            <p class="text-xs leading-5 text-gray-500">
              &copy; 2020 Electrocom Software PVT.LTD, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
});

export default Footer;
