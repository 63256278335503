import t1 from "../../../../Images/ModuleProduct/t1.png";
import t2 from "../../../../Images/ModuleProduct/t2.png";
import t3 from "../../../../Images/ModuleProduct/t3.png";
import t4 from "../../../../Images/ModuleProduct/t4.png";
import t5 from "../../../../Images/ModuleProduct/t5.png";
import t6 from "../../../../Images/ModuleProduct/t6.png";
import t7 from "../../../../Images/ModuleProduct/o3.png";
import Tax1 from "../../../../Images/ModuleProduct/tax1.png";
import Tax2 from "../../../../Images/ModuleProduct/tax2.png";
import Tax3 from "../../../../Images/ModuleProduct/tax3.png";
import Tax4 from "../../../../Images/ModuleProduct/tax4.png";
import Tax5 from "../../../../Images/ModuleProduct/tax5.png";

const moduleData = [
  {
    name: "Key features",
    back: Tax2,
    img: [t1, t2, t3, t4, t5, t6, t7],
    text: [
      "Income Tax Calculation Dashboard",
      "Computation of Income",
      "Auto generation of ITR forms",
      "Import JSON/EXCEL file",
      "New & Old Tax Regima",
      "26AS, AIS, TIS Direct Import",
      "Direct ITR upload",
    ],
  },
  {
    name: "Online Tool Option",
    back: Tax1,
    img: [t1, t2, t3, t4, t5, t6, t7],
    text: [
      "Update Return Status and History",
      "Update Refund Status",
      "Collect Assessee profile",
      "Download and view ITR-V/ ITR Form/ 26AS/ AIS/ TIS / JSON",
      "Download and view Order and Intimation",
      "Allowable Remuneration & Interest to Partners in Firm",
      "Collect multiple year data of assessea in a single click",
    ],
  },
  {
    name: "MultiAngle Analysis Reports",
    back: Tax3,
    img: [t1, t2, t3, t4, t5, t6, t7],
    text: [
      "Return filed / Late filed / Pending",
      "Assessee Register",
      "Refund Register",
      "Tax Register",
      "Advance Tax / TDS Register",
      "Order and Intimation",
      "Assessment Book",
    ],
  },
  {
    name: "Import Facility",
    back: Tax4,
    img: [t1, t2, t3, t4, t5, t6, t7],
    text: [
      "JSON / XML File",
      "Balance Sheet / P & L",
      "Form 26AS/AIS / TIS",
      "Previous Year data",
      "Data from Tally",
      "Data from Default Excel Sheets",
      "Tax Register",
    ],
  },
  {
    name: "E-Services",
    back: Tax5,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Direct e-Return & e-Payment through the software",
      "E-Return file viewer to view generated JSON file",
      "Facility to view ITR V & Intimation within the software",
      "Unique Pre-Validation facility to trace possible errors in e-Return",
      "Auto save of acknowledgment receipt no",
      "Direct data fetching for 26AS / AIS / TIS",
    ],
  },
  {
    name: "Other features",
    back: Tax1,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Auto Calculation of data",
      "Multi-year Income tax Summary",
      "Pre-Validation facility",
      "E-mail Manager",
      "Arrear/MAT/AMT Calculation",
      "Office Management Utilities",
    ],
  },
];

export default moduleData;

