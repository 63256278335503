import React from 'react'
import facebookIcon from "../../Images/Social/facebook.svg";
import twitterIcon from "../../Images/Social/twitter.svg";
import instagramIcon from "../../Images/Social/instagram.svg";
import linkedinIcon from "../../Images/Social/linkedin.svg";
import youtubeIcon from "../../Images/Social/youtube.svg";

const socialLinks = [
  {
    name: "Facebook",
    icon: facebookIcon,
    url: "https://www.facebook.com/electrocomsoftwareprivatelimited",
  },
  {
    name: "Twitter",
    icon: twitterIcon,
    url: "https://twitter.com/electrocom11",
  },
  {
    name: "Instagram",
    icon: instagramIcon,
    url: "https://www.instagram.com/easyofficesoftware/",
  },
  {
    name: "Linkedin",
    icon: linkedinIcon,
    url: "https://www.linkedin.com/company/electrocomsoftware-easyoffice",
  },
  {
    name: "YouTube",
    icon: youtubeIcon,
    url: "https://www.youtube.com/c/EASYOFFICESOFTWARE",
  },
];

const SocialNavBar = React.memo(({ Color }) => {
  return (
    <div className="flex items-center space-x-6">
      {socialLinks.map((link, index) => (
        <a
          key={index}
          href={link.url}
          className={`${Color} p-2 rounded-full transition duration-300 ease-in-out transform hover:scale-110 hover:shadow-lg`}
          style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)" }} 
        >
          <img
            src={link.icon}
            alt={link.name}
            style={{ width: "24px", height: "24px" }}
          />
        </a>
      ))}
    </div>
  );
});

export default SocialNavBar;