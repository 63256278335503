import React from "react";
import Right  from '../../Images/RightSvg.svg'

let ListItem = React.memo(({ text }) => (
  <li className="flex gap-x-3">
    <img src={Right} alt="" />
    <span>
      <strong className="font-semibold text-gray-900"> { text } </strong>
    </span>
  </li>
));

let Item = React.memo(
  ({ title, description, subDescription, bgImage, listItems, btnData }) => (
    <div className="item">
      <div className="relative isolate overflow-hidden bg-white px-6 py-10 sm:py-20 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width="200"
                height="200"
                x="50%"
                y="-1"
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y="-1" className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth="0"
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth="0"
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto grid grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 pl-14 lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:pr-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {title}
                </h1>
                <p className="mt-3 text-xl leading-8 text-gray-700">
                  {description}
                </p>
                <div>
                  <ul className="grid grid-cols-2">
                    {listItems.map((item, index) => (
                      <ListItem key={index} text={item} />
                    ))}
                  </ul>
                </div>
                <p className="mt-8 mb-5 text-lg">{subDescription}</p>
                <div className="sm:block flex">
                  {btnData.map((anchor, index) => (
                    <a
                      key={index}
                      href={anchor.link}
                      target="_blank"
                      className={anchor.className}
                    >
                      {anchor.text}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img src={bgImage} className=" rounded-2xl shadow-2xl" />
          </div>
        </div>
      </div>
    </div>
  )
);

export default Item;
