import React, { useState, useEffect } from "react";
import people from "./data";
import './style.css'
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";

const Review = React.memo(() => {
  const [index, setIndex] = useState(0);
  const { id, name, job, image, text } = people[index];
  const minIndex = 0;
  const maxIndex= people.length - 1;

  const checkNumber = (number) => {
    if (number > maxIndex) {
      return 0;
    }
    if (number < 0) {
      return maxIndex;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  const getRandomPerson=()=>{
    const getRandomIntInclusive=(min, max) =>{
      
      return Math.floor(Math.random() * (max - min + 1) + min); 
    }

    let randomIndex=getRandomIntInclusive(minIndex, maxIndex);
    if (randomIndex === index){
      randomIndex = index+1
    }
    setIndex(checkNumber(randomIndex));
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextPerson();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [index]);
  

  return (
    <>
      {/* <p className="text-center">Client Review</p> */}
      <article className="review">
        {/* <div className="img-container">
        <img src={image} alt={name} className="person-img" />
        <span className="quote-icon">
          <FaQuoteRight />
        </span>
      </div> */}
        <h4 className="author">{name}</h4>
        <p className="job">{job}</p>
        <p className="info">{text}</p>
        <div className="button-container">
          <button className="prev-btn" onClick={prevPerson}>
            <FaChevronLeft />
          </button>
          <button className="next-btn" onClick={nextPerson}>
            <FaChevronRight />
          </button>
        </div>
        <button className="random-btn" onClick={getRandomPerson}>
          Get Random Review
        </button>
      </article>
    </>
  );
});

export default Review;
