import React, { useState, useEffect } from "react";
import { Button } from "../../Components/ui/button";
import styles from "./style.module.css";

const Example = React.memo(() => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []); 

  return (
    <div className="bg-white relative isolate">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className={`absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 ${
            isVisible ? styles.backgroundAnimation : ""
          }`}
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width="200"
              height="200"
              x="50%"
              y="-1"
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth="0"
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth="0"
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div>
        <div
          className={`mx-auto max-w-3xl py-32 sm:py-48 lg:py-40 ${
            isVisible ? styles.fadeIn : ""
          }`}
        >
          <div className="text-center">
            <h1 class="font-black text-blue-900 text-5xl text-start lg:w-auto lg:text-center dark:text-white">
              India’s Leading Income Tax,{" "}
              <span class="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-500 dark:from-blue-400 dark:to-cyan-300">
                TDS, Audit, CMA & GST Software
              </span>
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Electrocom’s EasyOffice and EasyGST Taxation software is
              specifically designed & developed to revolutionize the{" "}
              <strong>
                complete Taxation management of CA & Tax Professionals.
              </strong>{" "}
              Our Software has created a reputation in the PAN India market for
              user-friendly operations, Accuracy, Taxation process automation &
              excellent after-sales support.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a href="https://www.easyofficesoftware.com/bank_details">
                <Button label={"Buy Now"} />
              </a>
              <a
                href="https://www.easyofficesoftware.com/"
                target="_blank"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


export default Example;
