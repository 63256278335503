import React, { useState } from "react";

const SecModule = React.memo(({ dataStore }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    dataStore.length > 0 ? dataStore[0].name : ""
  );

  return (
    <div className="pb-10 w-[93%]">
      <div className="sm:grid grid-cols-5 rounded-2xl shadow-2xl relative isolate overflow-hidden bg-white">
        <div class="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
        <div class="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
        <div className="col-span-1 flex flex-col items-center justify-center space-y-2 py-7 sm:py-0 bg-gray-300 bg-opacity-70">
          {dataStore.map((item, index) => (
            <div
              key={index}
              className={`py-2 w-52 bg-white tracking-wide text-gray-800 font-bold rounded border-b-2 border-blue-500 
            hover:border-blue-600 hover:bg-blue-500 active:bg-blue-500 hover:text-white duration-200 shadow-md 
            text-center px-6 inline-flex items-center justify-center cursor-pointer 
            ${selectedCategory === item.name ? "bg-blue-500" : ""}`}
              style={{
                backgroundColor:
                  selectedCategory === item.name ? "#2196f3" : "",
                color:
                  selectedCategory === item.name ? "#ffffff" : "",
              }}
              onClick={() => setSelectedCategory(item.name)}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="col-span-4 relative isolate overflow-hidden">
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width="200"
                  height="200"
                  x="50%"
                  y="-1"
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="-1" className="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth="0"
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth="0"
                fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
              />
            </svg>
          </div>
          <div className="item active items-center sm:grid grid-cols-2">
            <div className="p-10">
              {dataStore.map((item, index) => (
                <img
                  src={item.back}
                  className="rounded-2xl shadow-2xl"
                  style={{
                    display: selectedCategory === item.name ? "block" : "none",
                  }}
                  key={index}
                  alt=""
                />
              ))}
            </div>
            <div className="flex flex-col sm:items-start items-center sm:px-0 px-2 py-3 sm:py-7 justify-center">
              {dataStore.map((item, index) => (
                <div
                  key={index}
                  className="space-y-4"
                  style={{
                    display: selectedCategory === item.name ? "block" : "none",
                  }}
                >
                  {item.img.map((img, i) => (
                    <div
                      className="flex items-center text-black text-xl"
                      key={i}
                    >
                      <img
                        src={img}
                        className="sm:w-[40px] w-[35px] mr-2"
                        alt=""
                      />
                      {item.text[i]}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SecModule;
