import EasyOfficeImage from "../../Images/ProductBG/es5.jpg";
import EasyGSTImage from "../../Images/ProductBG/g1.jpg";
import EasyACCImage from "../../Images/ProductBG/as1.jpg";

let Products = [
  {
    name: "EasyOFFICE",
    title: "EasyOFFICE",
    description: `A Complete Taxation Software is a comprehensive solution that encompasses a range of modules, including Income Tax Software, TDS Software, Audit Software, CMA Data Software, and Office Management Utilities. The software is designed to cater to the needs of Tax Professionals and Chartered Accountants.`,
    subDescription: `The software's tax calculation & Advance online tools are the key features that simplify the tax preparation and return filing process, ensuring accuracy and compliance with the latest regulations. With its robust features and user-friendly interface, EasyOFFICE software is a preferred solution for Tax Professionals and it’s widely used across India. EasyOFFICE software can be downloaded free of cost.`,
    image: EasyOfficeImage,
    link: "https://www.easyofficesoftware.com/bank_details",
    listItems: [
      "IncomeTax Software",
      "TDS Return Software",
      "Audit e-filing Software",
      " Balance Sheet Software",
      "CMA Software",
      " Office Utilities",
    ],
    btnlink: [
      "https://www.easyofficesoftware.com/bank_details",
      "https://www.easyofficesoftware.com/public/demo/demo/EasyOfficeDemoSetup.exe",
      "https://www.easyofficesoftware.com/public/catalogue/EasyOFFICE-CATALOGUE.pdf",
    ],
  },
  {
    name: "EasyGST",
    title: "EasyGST",
    description: `EasyGST software is the Best & One-stop solution for all your GST compliances. The software is highly feature-rich, innovative & user-friendly making it an ideal solution for Tax Professionals & businesses.`,
    subDescription: `With features such as e-payment of taxes, auto intelligence ITC match/mismatch, Analysis report generation, Account data reconciliation with GSTR 2A and GSTR 2B, Online data automation with accuracy and much more. EasyGST software is designed to simplify the return e-filing process resulting in GST related workflow quick and easy for businesses.`,
    image: EasyGSTImage,
    link: "https://www.easyofficesoftware.com/bank_details",
    listItems: [
      "Single Window dashboard",
      "Import from Tally & Excel",
      "E-Invoice & E-way Bill",
      "Online Activities",
      "Return Statisticse",
      "Verify Bulk GSTIN",
    ],
    btnlink: [
      "https://www.easyofficesoftware.com/bank_details",
      "https://www.easyofficesoftware.com/public/demo/demo/EasyGSTDemoSetup.exe",
      "https://www.easyofficesoftware.com/public/catalogue/EasyGST-CATALOGUE.pdf",
    ],
  },
  {
    name: "EasyACC",
    title: "EasyACC",
    description: `EasyACC is a complete, GST-ready financial accounting software that is designed to meet the needs of small and medium businesses, traders, retailers, dealers, distributors and manufacturing units. EasyACC software is an ideal solution for businesses for Inventory and Invoicing Management.`,
    subDescription: `With its user-friendly advanced features, EasyACC Software is an ideal solution for businesses looking to simplify their billings, cheque/cash management, payment reminders, orders and other financial operations. EasyACC Software is available with special modules for the Textile industry, Garment industry, Engineering units, Job works, Agency business and Travel agents.`,
    image: EasyACCImage,
    link: "https://www.easyofficesoftware.com/bank_details",
    listItems: [
      " Invoicing",
      "Inventory",
      "Account Books",
      "Bank/Cash/JV/Ledger",
      "Debit note/Credit note",
      "Cash Management",
    ],
    btnlink: [
      "https://www.easyofficesoftware.com/bank_details",

      "https://www.easyofficesoftware.com/public/demo/demo/EasyAccDemoSetup.exe",
      "https://www.easyofficesoftware.com/public/catalogue/EasyACC-CATALOGUE.jpg",
    ],
  },
];

export default Products;

