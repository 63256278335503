import React from 'react';
import './output.css';
import Review from './feacture/Review/review';
import MyComponent from './feacture/Products/MyComponent';
import Header from './feacture/header/Header'
import Footer from './feacture/footer/Footer';
import Hero from "./feacture/hero/Hero";
import ContactForm from './feacture/contact/contactForm';
import FAQ from './feacture/FAQs/Faq';
import About from './feacture/About/About';
import MainProducts from './feacture/MainProducts/mainProducts';
import InfoGraph from './feacture/Infograph/InfoGraph';
import TutorialVideo from './feacture/TutorialVideo/TutorialVideo';
import OFM from './feacture/Module/OMU/Ofm';
import MainSecond from './feacture/Module/secModule/MainSecond';
import Ofm from './feacture/OMU/Ofm';
import Client from './feacture/EstimatedClient/Client';





function App() {
  
  return (
    <>
      <Header />
      <Hero />
      <About />
      <MyComponent />
      <MainProducts />
      <InfoGraph />
      <MainSecond />
      <Ofm />
      {/* <OFM /> */}
      {/* <Review /> */}
      <ContactForm />
      <FAQ />
      <TutorialVideo />
      <Client />
      <Footer />
    </>
  );
}

export default App;
