import React from "react";
import "./style.css";
import SvgIcon from '../../Images/doubleRight.svg'
import cardData from "./data";

const Card = React.memo(({ title, imageUrl, content }) => {
  return (
    <div className="p-3">
      <div className="card">
        <div className="card-info">
          <div className="flex justify-center h-[100px]">
            <img src={imageUrl} className="w-4/6 h-[70px]" alt="" />
          </div>
          {/* <p className="title text-3xl text-black font-bold mt-3">{title}</p> */}
          <div className="m-0 p-0">
            {content.map((paragraph, index) => (
              <p
                className="p-0 text-black my-1 items-start flex text-lg font-medium"
                key={index}
              >
                <img src={SvgIcon} className="mr-1 mt-2" alt="" />
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Card;
