import t1 from "../../../../Images/ModuleProduct/t1.png";
import t2 from "../../../../Images/ModuleProduct/t2.png";
import t3 from "../../../../Images/ModuleProduct/t3.png";
import t4 from "../../../../Images/ModuleProduct/t4.png";
import t5 from "../../../../Images/ModuleProduct/t5.png";
import t6 from "../../../../Images/ModuleProduct/t6.png";
import ProductBG from "../../../../Images/ProductBG/es5.jpg";

const module4Data = [
  {
    name: "CMA Data Preparation",
    back: ProductBG,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Multi year CMA data report",
      "Multiple Formats of Report",
      "Export report in EXCEL format",
      "Loan Calculator",
      "Late payment & Interest calculation",
      "Late Fee Calculation",
    ],
  },
  {
    name: "MPBF statement",
    back: ProductBG,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Automatic preparation statements",
      "Analysis of Balance Sheet ",
      "Download Requested Files",
      "Maximum Permissible Bank Finance",
      "Fund flow",
      " Ratio Statement",
    ],
  },
  {
    name: "Office Management Utilities",
    back: ProductBG,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Revised Return through Correction module",
      "Direct access to the TAN registration page",
      "Download consolidated .TDS file for revised returns",
      "Tax Register",
      "Deductee-wise break-up of challans",
      "Order and Intimation",
    ],
  },
  {
    name: "Auto Generation",
    back: ProductBG,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "E-TDS FVU file & form 27A",
      "TDS Certificates (16,16A,27D)",
      "Deductee-wise break-up of challans",
      "Deductors, Deductees, Employees",
      "TDS & TCS Challan details",
      "Deductee-wise break-up of challans",
    ],
  },
];

export default module4Data;
