import React from "react";
import Review from "../Review/review";
import { Button } from "../../Components/ui/button";

const inputData = [
  {
    value: "First Name*",
    type: "text",
  },
  {
    value: "Mobile Number*",
    type: "text",
  },
  {
    value: "Contact Number",
    type: "text",
  },
  {
    value: "EmailID*",
    type: "email",
  },
  {
    value: "Address Line 1",
    type: "text",
  },
  {
    value: "Address Line 2",
    type: "text",
  },
  {
    value: "Area*",
    type: "text",
  },
  {
    value: "City*",
    type: "text",
  },
  {
    value: "State*",
    type: "text",
  },
  {
    value: "Pin Code",
    type: "text",
  },
];

const ContactForm = React.memo(() => {
  return (
    <>
      <div className=" py-32 sm:flex justify-evenly sm:space-x-9 items-start sm:px-20 px-5">
        <div className="sm:w-1/2">
          <p className="font-bold text-5xl text-black text-center mt-6 pb-8">
            Our Happy Customers
          </p>
          <Review />
        </div>
        <div className="sm:w-1/2 relative sm:isolate text-center">
          <div class="hidden sm:block absolute inset-0 -z-10 overflow-hidden">
            <svg
              class="absolute left-[max(50%,25rem)] top-0 h-full w-full -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(30rem_30rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width="100"
                  height="100"
                  x="50%"
                  y="-1"
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  stroke-width="0"
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                stroke-width="0"
                fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
              />
            </svg>
          </div>
          <p className="font-bold text-5xl text-black mt-6 pb-8">Inquire</p>
          <div className="grid grid-cols-2 sm:gap-5">
            {inputData.map((item, index) => (
              <input
                key={index}
                type={item.type}
                placeholder={item.value}
                className="p-2 border sm:mb-0 mb-3 mx-1 sm:mx-0 border-gray-400 rounded-lg shadow-xl"
              />
            ))}
          </div>
          <div className=" mt-5">
            <input
              type="text"
              className="w-full border py-7 px-2 mb-5 border-gray-400 rounded-lg shadow-xl"
              placeholder="Description.."
            />
            <Button label={"Submit"} />
          </div>
        </div>
      </div>
    </>
  );
});

export default ContactForm;
