import React,{memo} from "react";
import Card from "./card";
import cardData from "./data";
import Slidebard from "../../Components/ui/Slidebard";

const MyComponent = React.memo(() => {
  return (
    <div className="bg-white w-full mt-[1000px] sm:mt-0 p-7 sm:p-5 overflow-hidden">
      <p className="text-center text-black text-5xl font-bold">Our Areas Of Expertise </p>
      <Slidebard
        MainContent={cardData.map((card, index) => (
          <div key={index}>
            <Card
              title={card.title}
              imageUrl={card.imageUrl}
              content={card.content}
            />
          </div>
        ))}
      />
    </div>
  );
});

export default memo(MyComponent);
