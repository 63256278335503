import React, { useState } from "react";
import SocialNavBar from "../../Components/ui/SocialNavBar";

const FAQ = React.memo(() => {
  const [questions, setQuestions] = useState([
    {
      question: "Q1. Which Software is Best for CA & Tax Professionals?",
      answer:
        "EasyOffice is a Complete Taxation software that offers comprehensive solutions for Income Tax Returns, TDS/TCS return filing, Audit e-Filing, Balance Sheet Preparation, CMA Data reports and Office Management utilities. This software helps individuals and businesses to file their tax returns quickly and easily. It offers various additional features for office management and has the ability to import XML/JSON files, making it one of the best taxation software in India among tax professionals. ",
    },
    {
      question: "Q2. Any user-friendly software solution for return filing?",
      answer:
        "Yes, Easyoffice software is designed to be user-friendly and easy to operate. It has a simple and intuitive interface that guides users through the tax filing process, making it easy for both beginners and experienced users.",
    },
    {
      question:
        "Q3. Does Easyoffice software provide online support and assistance?",
      answer:
        "Yes, Easyoffice provides online support and assistance to its users. Users can access a comprehensive knowledge video tutorial and FAQs to help them with their tax filing needs. Users can also contact EasyOFFICE’s customer support team via email, phone, or chat for any queries or issues.",
    },
    {
      question:
        "Q4. Can data be imported from other Taxation software into EasyOffice Income Tax Software?",
      answer: "Yes, EasyOffice Income Tax Module includes features for importing data from other Taxation software using JSON / XML files. This makes it easy to transfer Return data from other software into EasyTAX for use in tax calculations and reporting.",
    },
    {
      question: "Q5. What are the key features of EasyTax Income Tax Software?",
      answer: "Yes, you can easily and quickly prepare and file Income Tax Returns (ITR) 1 to 7 directly through EasyTax software or copy the JSON and upload it on the ITD Portal.",
    },
  ]);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <>
      <div className="sm:flex bg-white px-3 py-3 sm:px-20 justify-between items-center">
        
        <div className="flex flex-col justify-center items-center sm:justify-start sm:items-start text-center sm:text-start relative h-full sm:w-1/2 bg-blue-gray-200 p-14">
            <h2 className="text-4xl sm:text-5xl font-bold">Have Any Question's ?</h2>

            <div className=" mt-24 sm:mt-32">
              <SocialNavBar Color={'bg-gray-100'}/>
            </div>
          </div>
       
        <div className="faq sm:w-1/2 py-9">
          {questions.map((q, index) => (
            <div
              key={index}
              className="faq-item cursor-pointer px-5 sm:px-10 my-3"
              onClick={() => toggleQuestion(index)}
            >
              <div className="flex items-center">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 mr-2 transition-transform ${
                    activeIndex === index ? "rotate-45" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <div
                  className={`faq-question p-3 text-xl font-bold rounded-lg bg-transparent`}
                >
                  {q.question}
                </div>
              </div>

              {activeIndex === index && (
                <div className="faq-answer font-medium text-lg p-3 mt-1 ">
                  {q.answer}
                </div>
              )}
              <hr className="w-full h-2" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default FAQ;
