import { transform } from "framer-motion";
import React from "react";

const ContentSlider = React.memo(({
  data,
  AddContent,
  selectedProduct,
  setSelectedProduct,
}) => {
  const handleProductClick = (productName) => {
    setSelectedProduct(productName);
  };

  return (
    <section className="mt-5">
      <div className="thumbnail1 sm:grid grid-cols-3 bg-[#fffdfc] max-sm:px-3 border-2 rounded-xl mx-3 py-2 px-10">
        {data.map((product) => (
          <div
            key={product.name}
            className={`item cursor-pointer uppercase rounded-s-lg group py-2 text-2xl max-sm:text-lg font-bold flex w-full justify-center items-center hover:scale-105 transition-transform duration-300 ease-in-out ${
              selectedProduct === product.name && "active"
            }`}
            onClick={() => handleProductClick(product.name)}
            style={{
              backgroundColor:
                selectedProduct === product.name ? "#dde8df" : "",
              transform:
              selectedProduct === product.name ? "scale(1.10)" : "",
            }}
          >
            {product.title}
          </div>
        ))}
      </div>

      <div>{AddContent}</div>
    </section>
  );
});

export default ContentSlider;
