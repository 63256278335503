import React from 'react'
import { Button } from '../../Components/ui/button';

const AboutCard = React.memo(({ icon, title, description }) => {
  return (
    <div className="bg-white p-7 flex flex-col w-full h-[350px] border border-black shadow-2xl relative  max-sm:mb-5">
      <div>{icon}</div>
      <div className="text-2xl font-semibold">{title}</div>
      <div className="text-base my-4">{description}</div>
      <a href="#" className="absolute bottom-6">
        
        {/* <Button label={"Know More"}/> */}
      </a>
    </div>
  );
});

export default AboutCard;
