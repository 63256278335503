import React from 'react'
import SecModule from './SecModule';
import moduleData from './Data/TaxData';
import module2Data from './Data/AuditData';
import module3Data from './Data/TdsData';
import module4Data from './Data/CmaData';
import SecModuleRight from './SecModuleRight';

const MainSecond = React.memo(() => {
  return (
    <>
      <div className="flex flex-col items-center">
        <div className="text-center text-4xl font-semibold my-3">
          Income Tax Software
        </div>
        <SecModule dataStore={moduleData} />
      </div>
      <div className="flex flex-col items-center">
        <div className="text-center text-4xl font-semibold my-3">
          TDS & TCS software
        </div>
        <SecModuleRight dataStore={module3Data} />
      </div>
      <div className="flex flex-col items-center">
        <div className="text-center text-4xl font-semibold my-3">
          Audit e-filing & Balance Sheet
        </div>
        <SecModule dataStore={module2Data} />
      </div>
      <div className="flex flex-col items-center">
        <div className="text-center text-4xl font-semibold my-3">
          CMA Data preparation
        </div>
        <SecModuleRight dataStore={module4Data} />
      </div>
    </>
  );
});

export default MainSecond;