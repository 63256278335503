import t1 from "../../../../Images/ModuleProduct/t1.png";
import t2 from "../../../../Images/ModuleProduct/t2.png";
import t3 from "../../../../Images/ModuleProduct/t3.png";
import t4 from "../../../../Images/ModuleProduct/t4.png";
import t5 from "../../../../Images/ModuleProduct/t5.png";
import t6 from "../../../../Images/ModuleProduct/t6.png";
import t7 from "../../../../Images/ModuleProduct/o3.png";
import tds1 from "../../../../Images/ModuleProduct/tds1.jpg";
import tds2 from "../../../../Images/ModuleProduct/tds2.jpg";
import tds3 from "../../../../Images/ModuleProduct/tds3.jpg";
import tds4 from "../../../../Images/ModuleProduct/tds4.jpg";

const module3Data = [
  {
    name: "Key features",
    back: tds1,
    img: [t1, t2, t3, t4, t5, t6, t7],
    text: [
      "Prepare & Upload TDS & TCS Returns",
      "TDS / TCS Certificate",
      "Default Prediction Analysis",
      "Import / Export facility",
      "Employees’ Annual Taxable Salary Calculations",
      "Bulk PAN Verification",
      "Form 15G & 15H",
    ],
  },
  {
    name: "Correction Module",
    back: tds3,
    img: [t1, t2, t3, t4],
    text: [
      "Revised Return through Correction module",
      "Direct access to the TAN registration page",
      "Download consolidated .TDS file for revised returns",
      "Direct Upload",
    ],
  },
  {
    name: "Interfacing with TRACES & ITD",
    back: tds2,
    img: [t1, t2, t3, t4, t5],
    text: [
      "Fully Integrated with TRACES",
      "Automated TRACES Requests ",
      "Download Requested Files",
      "Validation of Lower Deduction Certificate",
      "View of Return filed status, Challan status etc.",
    ],
  },
  {
    name: "Default Prediction Analysis",
    back: tds1,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "PAN verification with name Extraction",
      "Challan details & verifications",
      "Short Deductions",
      "Lower Deductions",
      "Late payment & Interest calculation",
      "Late Fee Calculation",
    ],
  },

  {
    name: "Reports & Analysis",
    back: tds4,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Certificate Register",
      "Challan Register",
      "Transaction Register",
      "Deductors, Deductees, Employees",
      "Return filled Information register",
      " Deductors, Deductees & Employees Register",
    ],
  },
  {
    name: "Other features",
    back: tds1,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Deductor, Deductees, Employees, Master",
      " Challan E-payment & Verification",
      " Compliance check for 206AB and 206CCA ",
      "Interest and Late fee Calculation",
      "TDS data report for Audit",
      "Return file dashboard",
    ],
  },
];

export default module3Data;
