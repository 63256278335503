import React from "react";
import AboutCard from "./aboutCard";
import BackgroundImg from "../../Images/bg2.jpg";
import LazyLoad from "react-lazyload";

const About = React.memo(() => {
  return (
    <div id="About_Us">
      <div className="relative">
        <section
          className="w-full h-[20vh] lg:h-[50vh] bg-cover"
          style={{ backgroundImage: `url(${BackgroundImg})` }}
        />
      </div>

      <div className="w-full h-[400px] lg:h-[450px] bg-[#f1f5f8] relative">
        <div className="absolute bg-[#f1f5f8] rounded-xl border-2 border-[#95c3ff] -top-[4%] md:-top-[40%] shadow-2xl left-1/2 -translate-x-1/2 h-fit w-full md:w-[100%] lg:w-[92%] overflow-hidden text-black">
          <div className="text-center py-8">
            <h1 className="text-5xl font-bold mb-5">Why Us</h1>
            <p className="text-lg">
              We are a professionally managed organization engaged in the field
              of Software Development for more than 15 years.
            </p>
          </div>
          <div className="block sm:flex items-center sm:px-14 px-7 pb-6 sm:space-x-14">
            <AboutCard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 -960 960 960"
                  width="3rem"
                  fill="#ffd606"
                >
                  <path d="M411-480q-28 0-46-21t-13-49l12-72q8-43 40.5-70.5T480-720q44 0 76.5 27.5T597-622l12 72q5 28-13 49t-46 21H411Zm24-80h91l-8-49q-2-14-13-22.5t-25-8.5q-14 0-24.5 8.5T443-609l-8 49ZM124-441q-23 1-39.5-9T63-481q-2-9-1-18t5-17q0 1-1-4-2-2-10-24-2-12 3-23t13-19l2-2q2-19 15.5-32t33.5-13q3 0 19 4l3-1q5-5 13-7.5t17-2.5q11 0 19.5 3.5T208-626q1 0 1.5.5t1.5.5q14 1 24.5 8.5T251-596q2 7 1.5 13.5T250-570q0 1 1 4 7 7 11 15.5t4 17.5q0 4-6 21-1 2 0 4l2 16q0 21-17.5 36T202-441h-78Zm676 1q-33 0-56.5-23.5T720-520q0-12 3.5-22.5T733-563l-28-25q-10-8-3.5-20t18.5-12h80q33 0 56.5 23.5T880-540v20q0 33-23.5 56.5T800-440ZM0-240v-63q0-44 44.5-70.5T160-400q13 0 25 .5t23 2.5q-14 20-21 43t-7 49v65H0Zm240 0v-65q0-65 66.5-105T480-450q108 0 174 40t66 105v65H240Zm560-160q72 0 116 26.5t44 70.5v63H780v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5Zm-320 30q-57 0-102 15t-53 35h311q-9-20-53.5-35T480-370Zm0 50Zm1-280Z" />
                </svg>
              }
              title="We Promises Easy & Efficient Software"
              description="We are proud to be a leading provider of Taxation & financial Accounting software solutions in the industry.Each of our products is designed to deliver on our tagline of Easy, Effective & Efficient Software with a badge of Easy."
            />
            <AboutCard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 -960 960 960"
                  width="3rem"
                  fill="#08dab3"
                >
                  <path d="M475-160q4 0 8-2t6-4l328-328q12-12 17.5-27t5.5-30q0-16-5.5-30.5T817-607L647-777q-11-12-25.5-17.5T591-800q-15 0-30 5.5T534-777l-11 11 74 75q15 14 22 32t7 38q0 42-28.5 70.5T527-522q-20 0-38.5-7T456-550l-75-74-175 175q-3 3-4.5 6.5T200-435q0 8 6 14.5t14 6.5q4 0 8-2t6-4l136-136 56 56-135 136q-3 3-4.5 6.5T285-350q0 8 6 14t14 6q4 0 8-2t6-4l136-135 56 56-135 136q-3 2-4.5 6t-1.5 8q0 8 6 14t14 6q4 0 7.5-1.5t6.5-4.5l136-135 56 56-136 136q-3 3-4.5 6.5T454-180q0 8 6.5 14t14.5 6Zm-1 80q-37 0-65.5-24.5T375-166q-34-5-57-28t-28-57q-34-5-56.5-28.5T206-336q-38-5-62-33t-24-66q0-20 7.5-38.5T149-506l232-231 131 131q2 3 6 4.5t8 1.5q9 0 15-5.5t6-14.5q0-4-1.5-8t-4.5-6L398-777q-11-12-25.5-17.5T342-800q-15 0-30 5.5T285-777L144-635q-9 9-15 21t-8 24q-2 12 0 24.5t8 23.5l-58 58q-17-23-25-50.5T40-590q2-28 14-54.5T87-692l141-141q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l11 11 11-11q24-23 53.5-35t60.5-12q31 0 60.5 12t52.5 35l169 169q23 23 35 53t12 61q0 31-12 60.5T873-437L545-110q-14 14-32.5 22T474-80Zm-99-560Z" />
                </svg>
              }
              title="Customer First Priority"
              description="At Client First Priority Company, We put our clients at the heart of everything we do. Out mission is to deliver exceptional service and tailored solutions that meet your unique needs. With a dedicated team of professionals, we prioritize your satisfaction and strive for excellence in every interaction."
            />
            <AboutCard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 -960 960 960"
                  width="3rem"
                  fill="#fe9693"
                >
                  <path d="M480-120 80-600l120-240h560l120 240-400 480Zm-95-520h190l-60-120h-70l-60 120Zm55 347v-267H218l222 267Zm80 0 222-267H520v267Zm144-347h106l-60-120H604l60 120Zm-474 0h106l60-120H250l-60 120Z" />
                </svg>
              }
              title="Competitive Pricing"
              description="We are committed to providing top-quality products at unbeatable prices. Our focus is on delivering exceptional value without compromising on support. By leveraging efficient processes and strong support team. we ensure cost savings that are passes directly to guide our client's."
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(About);
