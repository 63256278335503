import { Fragment, useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import easyOfficeIcon from "../../Images/Products/easyOffice.webp";
import easyGstIcon from "../../Images/Products/easyGst.webp";
import easyAccIcon from "../../Images/Products/easyAcc.webp";
import easyTaxIcon from "../../Images/Products/easyTax.webp";
import easyAuditIcon from "../../Images/Products/easyAudit.webp";
import easyTdsIcon from "../../Images/Products/easyTds.webp";
import easyCmaIcon from "../../Images/Products/EasyCma.webp";
import Video from '../../Images/video.svg'
import Contact from '../../Images/contact.svg'
import Bank from '../../Images/bank.svg'
import faqs from '../../Images/question.svg'
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { Button } from "../../Components/ui/button";
import Logo from "../../Images/logo.png";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(
        (prevScrollPos > currentScrollPos &&
          prevScrollPos - currentScrollPos > 70) ||
          currentScrollPos < 10
      );
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  const products = useMemo(
    () => [
      {
        name: "EasyOFFICE",
        description: "A Complete Taxation Software",
        href: "#",
        icon: easyOfficeIcon,
      },
      {
        name: "EasyGST",
        description: "Goods & Services Tax Software",
        href: "#",
        icon: easyGstIcon,
      },
      {
        name: "EasyACC",
        description: "Financial Accounting Software",
        href: "#",
        icon: easyAccIcon,
      },
      {
        name: "EasyTAX",
        description: "Income Tax Software",
        href: "#",
        icon: easyTaxIcon,
      },
      {
        name: "EasyTDS",
        description: "TDS & TCS Return Software",
        href: "#",
        icon: easyTdsIcon,
      },
      {
        name: "EasyAUDIT",
        description: "Audit & Balance sheet Software",
        href: "#",
        icon: easyAuditIcon,
      },
      {
        name: "EasyCMA",
        description: "CMA Data Software",
        href: "#",
        icon: easyCmaIcon,
      },
    ],
    []
  );

  const help = useMemo(
    () => [
      {
        name: "SupportVideo",
        href: "https://www.easyofficesoftware.com/video",
        icon: Video,
      },
      {
        name: "Contact",
        href: "",
        icon: Contact,
      },
      {
        name: "Bank Detail",
        href: "https://www.easyofficesoftware.com/bank_details",
        icon: Bank,
      },
      {
        name: "Software FAQ's",
        href: "https://www.easyofficesoftware.com/faq",
        icon: faqs,
      },
    ],
    []
  );

  const navigationItems = useMemo(
    () => [
      { name: "Product", items: products },
      {
        name: "Download",
        href: "https://www.easyofficesoftware.com/downloads",
      },
      { name: "Blog", href: "https://www.easyofficesoftware.com/blog" },
      {
        name: "Help",
        items: help,
      },
    ],
    [products, help]
  );

  return (
    <header
      className={`bg-white z-10 fixed w-full py-1 top-0 transition-transform duration-200 ease-in-out ${
        visible ? "transform translate-y-0" : "transform -translate-y-full"
      }`}
    >
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a
            href="https://www.easyofficesoftware.com/"
            className="flex items-center px-2 text-white flex-1"
          >
            <img src={Logo} className="w-1/3" alt="Easy Office Logo" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          {navigationItems.map((item, index) => (
            <Fragment key={index}>
              {item.items ? (
                <Popover className="relative">
                  <PopoverButton className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900">
                    {item.name}
                    <ChevronDownIcon
                      className="h-5 w-5 flex-none text-gray-400"
                      aria-hidden="true"
                    />
                  </PopoverButton>
                  <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-2">
                        {item.items.map((subItem) => (
                          <div
                            key={subItem.name}
                            className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                          >
                            <div className="flex h-14 w-14 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                              <img src={subItem.icon} alt={subItem.name} />
                            </div>
                            <div className="flex-auto">
                              <a
                                href={subItem.href}
                                className="block text-xl font-semibold text-gray-900"
                              >
                                {subItem.name}
                                <span className="absolute inset-0" />
                              </a>
                              <p className="mt-1 text-lg text-gray-600">
                                {subItem.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </PopoverPanel>
                  </Transition>
                </Popover>
              ) : (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              )}
            </Fragment>
          ))}
        </PopoverGroup>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="https://www.easyofficesoftware.com/public/assets_front/software/LiveCare.exe">
            <Button label={"Livecare"} />
          </a>
          <div className="ml-2">
            <a href="https://www.easyofficesoftware.com/product_demo">
              <button className="py-2 px-5 font-semibold rounded-lg bg-white border border-black shadow hover:bg-primary/90">
                Demo
              </button>
            </a>
          </div>
        </div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a
              href="https://www.easyofficesoftware.com/"
              className="flex items-center px-2 text-white flex-1"
            >
              <img src={Logo} className="w-1/3" alt="Easy Office Logo" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              {navigationItems.map((item, index) => (
                <div key={index} className="space-y-2 py-6">
                  {item.items ? (
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <DisclosureButton className="flex w-full font-serif items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                            {item.name}
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </DisclosureButton>
                          <DisclosurePanel className="px-3 mt-2">
                            {item.items.map((subItem) => (
                              <a
                                key={subItem.name}
                                href={subItem.href}
                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                              >
                                {subItem.name}
                              </a>
                            ))}
                          </DisclosurePanel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <a
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};

export default Header;
